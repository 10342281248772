import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyle = createGlobalStyle`

    *,*::before, *::after,a{
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
        padding: 0;
        text-decoration: none;
        color: inherit;
    }
    canvas{ max-height:250px; }
    html{
        font-size: 62.5%;

::-webkit-scrollbar {
  width: 13px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 12px #2E2D41; 
  border-radius: 10px;
  /* background: #2E2D41; */
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #BBCE3F; 
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(187,206,63);
}


    }

    body{
        font-size: 1.6rem;
        /* Lato was added in index html file */
        font-family: 'Open-sans', sans-serif;
        margin: 0;
        color: #2E2D41;
        cursor: default;
    }
    .Toastify__toast--success {
        background: linear-gradient(45deg,#56CBFF 10%,#0496FF 100%);
     }
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
    /* input[type="text"]:focus {
  border-color: #00bafa;
}
input[type="text"]:focus + label[placeholder]:before {
  color: #00bafa;
} */
input[type="text"]:focus + label[placeholder]:before,
input[type="text"]:valid + label[placeholder]:before {
          transition-duration: 0.2s;
          transform: translate(10px, -1.5em) scale(0.9, 0.9);
}
input[type="text"]:invalid + label[placeholder][alt]:before {
  content: attr(alt);
}
input[type="text"] + label[placeholder] {
  display: block;
  pointer-events: none;
  line-height: 1.25em;
  margin-top: calc(-3em - 2px);
  margin-bottom: calc((3em - 1em) + 2px);
}
input[type="text"] + label[placeholder]:before {
  content: attr(placeholder);
  display: inline-block;
  margin: 0 calc(1em + 2px);
  padding: 0 2px;
  white-space: nowrap;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
  background-image: linear-gradient(to bottom, #fff, #fff);
  background-size: 100% 5px;
  background-repeat: no-repeat;
  background-position: center;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
    button{
  outline: none;
}
a, img, div{
  -webkit-tap-highlight-color: transparent;
}




.fb_dialog,  .fb_dialog_advanced {
   z-index: 9998 !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,

input:-internal-autofill-selected { 
  background-color: transparent
}
    
`;

export default GlobalStyle;
